import React, { useState, useEffect, useRef } from "react";
import { BsPerson } from "react-icons/bs";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import PhoneInput from "react-phone-input-2";
import { MdOutlineAttachEmail, MdOutlinePhone } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import "../Style/CSS.css";
import defaultProfilePic from "../../src/pages/components/assets/icons/person.png";
import { ApiEndPoint, decodeToken } from "../pages/components/utils/utlis";
import loginStyle from "../pages/ContactForm/contact.module.css";
import { CiEdit } from "react-icons/ci";

const Admin_profile = () => {
  const jwtToken = sessionStorage.getItem("jwtToken");
  const decod = decodeToken(jwtToken);
  const { decodedToken } = decod;
  const { userId } = decodedToken;
  const [adminData, setAdminData] = useState({ profilePicture: "" });
  const [editableFields, setEditableFields] = useState(true);
  const [profilePicBase64, setProfilePicBase64] = useState("");
  const [info, setInfo] = useState(true);
  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const handlePhoneChange = (value) => {
    setAdminData((prevData) => ({ ...prevData, phone: value }));
  };
  useEffect(() => {
    if (editableFields) {
      nameInputRef.current.focus();
    }
  }, [editableFields]);
  const toggleEdit = () => {
    setEditableFields(!editableFields);
  };

  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/admins/${userId}`);
      console.log(response.data);
      setAdminData(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        setProfilePicBase64(base64String);
        setAdminData((prevData) => ({ ...prevData, profilePicture: base64String }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e, field) => {
    const value = e.target.value;
    setAdminData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSave = async () => {
    try {
      console.log("admin data before save", adminData);
      const response = await axios.put(`${ApiEndPoint}/admin/${userId}`, adminData);
      console.log(response.data.message);
      if (response.status === 200) {
        setAdminData(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <center style={{width:'100%'}}>
    {adminData ? (
      <div style={{ height: "85%", width: "30%" }}>
        <div
          style={{ height: "140px" }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="pt-1 d-flex flex-column justify-content-center">
            <div style={{ position: "relative", marginLeft: "1.6rem" }}>
              <label htmlFor="profilePicInput">
                <img
                  src={
                    adminData.profilePicture
                      ? `data:image/jpeg;base64,${adminData.profilePicture}`
                      : profilePicBase64
                      ? `data:image/jpeg;base64,${profilePicBase64}`
                      : defaultProfilePic
                  }
                  alt="Profile"
                  style={{
                    maxWidth: "90px",
                    maxHeight: "90px",
                    minWidth: "90px",
                    minHeight: "90px",
                    borderRadius: "50%",
                    border: "1px solid #24D366",
                    boxShadow: "#24D366 0px 2px 5px",
                  }}
                  className="avatar-img"
                />
              </label>
              {editableFields && (
                <input
                  type="file"
                  accept="image/*"
                  id="profilePicInput"
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
              )}
              
                <CiEdit
                  onClick={() => toggleEdit("name")}
                  style={{
                    position: "relative",
                    top: 2,
                    marginLeft: 1,
                    color: "black",
                    fontSize: 25,
                  }}
                />
            
            </div>
          </div>
        </div>
       
          {info ? (
            <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
              <div className="gap-2 d-flex flex-column justify-content-center">
                {/* Name Field */}
                <div className="gap-1 d-flex">
                  <div className={"text-start"}>
                    <label className={"form-label"}>
                      <p
                        className="ml-1 fw-semibold"
                        style={{ fontSize: "1.05rem" }}
                      >
                        Name
                      </p>
                    </label>
                    <div
                      className={"input-group bg-soft-light rounded-2"}
                      style={{ marginTop: -8, width: "30vw"}}
                    >
                      <span
                        className={"input-group-text"}
                        style={{ background: "aliceblue" }}
                      >
                        <BsPerson />
                      </span>
                      <input
                       style={{
                        borderColor: "#24D366", // Green border for unfocused state
                        boxShadow: "none", // Remove default Bootstrap shadow on focus
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = "#FF7640"; // Orange border on focus
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = "#24D366"; // Green border on unfocus
                      }}
                      
                        ref={nameInputRef}
                        className={
                          loginStyle["form-control-1"] +
                          " form-control-md form-control"
                        }
                        value={adminData.name}
                        onChange={(e) => handleChange(e, "name")}
                        type="text"
                        required
                        readOnly={!editableFields}
                      />
                    </div>
                  </div>
                </div>
                {/* Email Field */}
                <div className="gap-1 d-flex">
                  <div className={"text-start"}>
                    <label className={"form-label"}>
                      <p
                        className="ml-1 fw-semibold"
                        style={{ fontSize: "1.05rem" }}
                      >
                        Email
                      </p>
                    </label>
                    <div
                      className={"input-group bg-soft-light rounded-2"}
                      style={{ marginTop: -8, width: "30vw"}}
                    >
                      <span
                        className={"input-group-text"}
                        style={{ background: "aliceblue" }}
                      >
                        <MdOutlineAttachEmail />
                      </span>
                      <input
                       style={{
                        borderColor: "#24D366", // Green border for unfocused state
                        boxShadow: "none", // Remove default Bootstrap shadow on focus
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = "#FF7640"; // Orange border on focus
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = "#24D366"; // Green border on unfocus
                      }}
                      
                        ref={emailInputRef}
                        className={
                          loginStyle["form-control-1"] +
                          " form-control-md form-control"
                        }
                        value={adminData.email}
                        type="text"
                        required
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
                {/* Password Field */}
                <div className={"text-start"}>
                  <label className={"form-label"}>
                    <p
                      className="ml-1 fw-semibold"
                      style={{ fontSize: "1.05rem" }}
                    >
                      Password
                    </p>
                  </label>
                  <div
                    className={"input-group bg-soft-light rounded-2"}
                    style={{ marginTop: -8, width: "30vw"}}
                  >
                    <span
                      className={"input-group-text"}
                      style={{ background: "aliceblue" }}
                    >
                      <RiLockPasswordFill />
                    </span>
                    <input
                     style={{
                      borderColor: "#24D366", // Green border for unfocused state
                      boxShadow: "none", // Remove default Bootstrap shadow on focus
                    }}
                    onFocus={(e) => {
                      e.target.style.borderColor = "#FF7640"; // Orange border on focus
                    }}
                    onBlur={(e) => {
                      e.target.style.borderColor = "#24D366"; // Green border on unfocus
                    }}
                    
                      ref={passwordInputRef}
                      className={
                        loginStyle["form-control-1"] +
                        " form-control-md form-control"
                      }
                      value={adminData.password}
                      onChange={(e) => handleChange(e, "password")}
                      type="text"
                      name="password"
                      required
                      readOnly={!editableFields}
                    />
                  </div>
                </div>
                <div className="text-start">
                  <label className={"form-label"}>
                    <p
                      className="fw-bold"
                      style={{
                        marginLeft: "3px",
                        fontSize: "1.05rem",
                        marginBottom: "-2rem",
                      }}
                    >
                      WhatsApp Number
                    </p>
                  </label>
                  <div>
                    <PhoneInput
                      containerClass="form-control-md"

                      inputProps={{
                        name: "phone",
                        readOnly: !editableFields,
                        onFocus:(e) => {
                          e.target.style.borderColor = "#FF7640"; // Orange border on focus
                        },
                        onBlur:(e) => {
                          e.target.style.borderColor = "#24D366"; // Green border on unfocus
                        }
                      }}
                      containerStyle={{
                        borderRadius: "10px",
                      }}
                      disableSearchIcon={true}
                      inputStyle={{
                        width: "100%",
                        border: "1px solid #24D366",
                        boxShadow: "none",
                        height: "37px",
                        
                      }}
                     
                      
                      
                      buttonStyle={{}}
                      required
                      country={"us"}
                      value={adminData.phone}
                      onChange={handlePhoneChange}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <button
                    onClick={handleSave}
                    style={{
                      width: "20%",
                      position: "relative",
                      color: "white",
                      background: "#FF7640",
                      marginTop: 4,
                      height: 35,
                      border: "none",
                      borderRadius: 10,
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
    
      </div>
    ) : (
      <></>
    )}
  </center>
  
  );
};

export default Admin_profile;
