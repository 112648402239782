import React, { useState, useEffect, useRef } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import btn1 from "../../pages/components/images/quick-reply-green.png";
import btn2 from "../../pages/components/images/share-button-green.png";
import Template from "../../pages/components/assets/icons/Template.png";
import Button from "../../pages/components/assets/icons/Button.png";
import Message from "../../pages/components/assets/icons/Message.png";

function MessageNode({ data, id, selected }) {
  const [input, setInput] = useState(data.label || "");
  const { setNodes } = useReactFlow();
  const textInputRefs = useRef([]);
  const [isInvalidLanguage, setIsInvalidLanguage] = useState(false);
  const [color, setColor] = useState("");
  const textareaRef = useRef(null);
  const [showButtons, setShowButtons] = useState(data.showButtons || false);
  const [inputs, setInputs] = useState(
    data.inputs || [{ text: "", link: "", showText: false, status: false }]
  );
  const [c, setC] = useState(data.count || 1);
  const [selectedLanguage, setSelectedLanguage] = useState(
    data.language || "en"
  );
  useEffect(() => {
    setInputs(data.inputs || [{ text: "", link: "" }]);
    setC(data.count || 1);
    setSelectedLanguage(data.language || "en");
    setShowButtons(data.showButtons || false);
  }, [data]);
  useEffect(() => {}, [selectedLanguage, textInputRefs]);
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedInputs = [...inputs];
    let regex;

    if (selectedLanguage === "en") {
      regex = /^[A-Za-z0-9\s,.;:!?'"\-<>*$\^~`_\[\](){}&@#\+\=%]*$/;
    } else if (selectedLanguage === "ar") {
      regex =
        /^[\u0600-\u06FF\u0750-\u077F0-9\s,.;:!?'"\-<>*$\^~`_\[\](){}&@#\+\=%]*$/;
    } else {
      regex = /.*/; // Default to allow any input if language is not recognized
    }

    // Handle changes based on the input name
    if (name === "text") {
      updatedInputs[index].text = value;

      // If the value is a URL, handle the URL logic
      if (isURL(value)) {
        updatedInputs[index].link = value;
        updatedInputs[index].text = ""; // Empty the text input field
        updatedInputs[index].showText = true; // Show the link input field
      } else {
        const inputElement = textInputRefs.current[index];
        if (!regex.test(value)) {
          inputElement.style.borderColor = "red";
          updatedInputs[index].text = "";
          console.log("Invalid input detected");
          return;
        } else {
          inputElement.style.borderColor = "transparent";
        }
      }
    } else if (name === "link") {
      if (value === "") {
        updatedInputs[index].link = "";
        textInputRefs.current[index]?.focus();
        updatedInputs[index].showText = false; // Hide the link input field
      } else {
        updatedInputs[index].link = value;
      }
    }

    // Update the inputs state and data object
    setInputs(updatedInputs);
    data.inputs = updatedInputs; // Update the data object
  };

  const handleAddInput = () => {
    if (c < 3) {
      const newInputs = [
        ...inputs,
        { text: "", link: "", showText: false, status: false },
      ];
      setInputs(newInputs);
      setC(c + 1);
      data.inputs = newInputs; // Update the data object
      data.count = c + 1; // Update the count in data object
    }
  };

  const handleRemoveInput = () => {
    if (c > 1) {
      const newInputs = inputs.slice(0, -1);
      setInputs(newInputs);
      setC(c - 1);
      data.inputs = newInputs; // Update the data object
      data.count = c - 1; // Update the count in data object
    }
  };

  const isURL = (str) => {
    const urlRegex =
      /\b((http|https|ftp):\/\/)?(([\w-]+\.)+[\w-]{2,})(:\d{2,5})?(\/[^\s]*)?\b/;
    return urlRegex.test(str);
  };

  useEffect(() => {
    setInput(data.label || "");
    setSelectedLanguage(data.language || "en");
    autoResizeTextarea();
  }, [data.label]);

  useEffect(() => {
    autoResizeTextarea();
  }, [input]);
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 2px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    setInput("");

    // Reset any previous validation
    setIsInvalidLanguage(false);

    // Clear the text value for all items in the inputs state
    const clearedInputs = inputs.map((input) => ({
      ...input,
      text: "",
    }));

    setInputs(clearedInputs);
    data.inputs = clearedInputs; // Ensure data object reflects the changes
  };

  const handleChange = (event) => {
    let { value } = event.target;
    autoResizeTextarea();

    // Language restriction logic
    let regex;
    if (selectedLanguage === "en") {
      regex = /^[A-Za-z0-9\s,.;:!?'"\-<>*$\^~`_\[\](){}&@#\+\=%]*$/;
    } else if (selectedLanguage === "ar") {
      regex =
        /^[\u0600-\u06FF\u0750-\u077F0-9\s,.;:!?'"\-<>*$\^~`_\[\](){}&@#\+\=%]*$/;
    } else {
      regex = /.*/; // Allow everything if no language is selected
    }

    // Check if the input matches the selected language
    if (!regex.test(value)) {
      setIsInvalidLanguage(true); // Apply red border if input is invalid
      return; // Exit early if input doesn't match the selected language
    } else {
      setIsInvalidLanguage(false); // Remove red border if input is valid
    }

    if (value.length > 550) {
      value = value.slice(0, 550);
    }

    const words = value.split(" ");
    let formattedValue = "";
    let currentLine = "";

    words.forEach((word) => {
      if (currentLine.length + word.length + 1 > 20) {
        formattedValue += currentLine + "\n";
        currentLine = word;
      } else {
        if (currentLine.length > 0) {
          currentLine += " ";
        }
        currentLine += word;
      }
    });

    if (currentLine.length > 0) {
      formattedValue += currentLine;
    }

    setInput(value);
    setC(value.length);
  };
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: { ...node.data, language: selectedLanguage, label: input },
          };
        }
        return node;
      })
    );
  }, [selectedLanguage, input, setNodes, id]);
  const handleFocus = (index) => {
    const newInputs = inputs.map((input, i) => ({
      ...input,
      status: i === index,
    }));
    setInputs(newInputs);
  };
  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 400)}px`;
    }
  };

  const handleToggle = () => {
    setShowButtons(!showButtons);
    data.showButtons = !showButtons; // Ensure data is updated correctly
  };

  return (
    <div
      style={{
        border: selected ? "1px solid #FF7640" : "1px solid #075E54",
        boxShadow: selected
          ? "0 4px 8px rgba(0,0,0,0.2)"
          : "0 2px 4px rgba(0,0,0,0.2)",
        backgroundColor: "#ece4e4",
        borderRadius: "5px",
      }}
      className={`template w-72  shadow-lg rounded-lg bg-white ${
        selected ? "border-solid border-1" : ""
      }`}
    >
      <div
        className="flex flex-col"
        style={{
          backgroundColor: "#ece4e4",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            background: "#25D366",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // Center items vertically

            color: "white",
            fontWeight: "bold",
            borderRadius: "0.375rem 0.375rem 0 0", // Top rounded corners
          }}
          className="px-2 py-1"
        >
          <span style={{ display: "flex", alignItems: "center" , fontSize: "16px",}}>
            ✉️ Template
          </span>
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            style={{
              background: "white",
              color: "black",
              borderRadius: "0.375rem",
              border: "none", // Hides the border
              outline: "none", // Removes the outline when focused
            }}
          >
            <option value="en">English</option>
            <option value="ar">Arabic</option>
          </select>

          <span style={{ fontWeight: "normal" }}>{input.length}/550</span>
        </div>

        <div className="px-2 pt-2 relative">
          <div className="text-xs w-68 font-normal text-black">
            <textarea
              ref={textareaRef}
              name="inp"
              value={input}
              onChange={handleChange}
              style={{
                minHeight: "30px",
                maxHeight: "100px",
                width: "100%",
                border: isInvalidLanguage ? "1px solid red" : "none",
                borderRadius: 4,
                outline: "none",
                resize: "none",
                overflowY: "auto",
                fontSize: "12px",
                // direction: selectedLanguage === "ar" ? "rtl" : "ltr",
                fontWeight: "500",
                color: "#4b5563",
                backgroundColor: "#ece4e4",
              }}
              placeholder={`Enter Message in ${
                selectedLanguage === "en" ? "English" : "Arabic"
              }`}
            />
          </div>
          {!showButtons && (
            <Handle
              id="bwww"
              type="source"
              position={Position.Right}
              style={{
                position: "absolute",
                right: -4,
                top: "40%",
                transform: "translateY(-50%)",
              }}
              className="w-1 rounded-full bg-gray-500"
            />
          )}
        </div>

        <div className="px-2">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderRadius: 10,
              backgroundColor: "#075E54",
              // Light gray background
            }}
            className="px-2 pt-2"
          >
            <div
              style={{ width: "90%", color: "white", fontSize: "16px" }}
              className="text-xs fw-200"
            >
              Buttons
            </div>
            <div
              style={{ width: "20%", marginTop: 1 }}
              className="pl-5 rounded cursor-pointer text-blue-500"
            >
              <label
                className="switch"
                style={{ height: "13px", width: "27px" }}
              >
                <input
                  type="checkbox"
                  checked={showButtons}
                  onChange={handleToggle}
                />
                <span className="slider2 round"></span>
              </label>
            </div>
          </div>

          {showButtons && (
            <div className="py-2">
              {inputs.map((input, index) => (
                <div
                  className="relative flex flex-col mb-2 p-2"
                  key={index}
                  style={{
                    borderBottomWidth: 1,
                    width: "100%",
                    borderColor: "#FF7640",
                    backgroundColor: "#075E54",
                    borderRadius: 10,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s, color 0.3s",
                    fontWeight: "14px",
                  }}
                  onClick={() => handleFocus(index)}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={input.showText ? btn2 : btn1}
                      height={10}
                      width={12}
                      style={{ marginRight: 5, color: "#FF7640" }}
                      alt="Button Icon"
                    />

                    <input
                      type="text"
                      placeholder="Text or URL"
                      style={{
                        width: "100%",
                        height: 25,
                        border: "none",
                        outline: "none",
                       
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "transparent",
                        borderRadius: 4,

                        color: "white",
                        fontSize: "16px",

                        backgroundColor: "#075E54",
                      }}
                      name="text"
                      value={input.text}
                      onChange={(event) => {
                        if (event.target.value.length <= 25) {
                          handleInputChange(index, event);
                        }
                      }}
                      ref={(el) => (textInputRefs.current[index] = el)}
                      onBlur={(e) => {
                        e.target.style.backgroundColor = "#075E54";
                        e.target.style.color = "white";
                      }}
                      onFocus={(e) => {
                        handleFocus(index);
                        e.target.style.backgroundColor = "#075E54";
                        e.target.style.color = "white";
                      }}
                      maxLength={25} // Enforces the maximum character limit directly in the input field
                    />

                    <div
                      style={{
                        color: "white",
                        fontSize: "14px",
                        position: "absolute",
                        top: "14px",
                        right: "10px",
                      }}
                    >
                      {input.text.length}/25
                    </div>
                  </div>
                  {input.showText && (
                    <input
                      type="text"
                      placeholder="Link"
                      style={{
                        width: "100%",
                        height: 25,
                        border:
                          selectedLanguage !== "en" &&
                          /[\u0600-\u06FF]/.test(input.link)
                            ? "1px solid red"
                            : "none",
                        outline: "none",
                        fontSize: 8,
                        
                        color: "white",
                        paddingLeft: 15,
                        backgroundColor: "#075E54",
                      }}
                      name="link"
                      value={input.link}
                      onChange={(event) => handleInputChange(index, event)}
                      onBlur={(e) => {
                        e.target.style.backgroundColor = "#075E54";
                        e.target.style.color = "white";
                      }}
                      onFocus={(e) => {
                        handleFocus(index);
                        e.target.style.backgroundColor = "#075E54";
                        e.target.style.color = "white";
                      }}
                    />
                  )}
                  {!input.showText && (
                    <Handle
                      id={`b-${index}`}
                      type="source"
                      position={Position.Right}
                      style={{
                        position: "absolute",
                        right: -11,
                        top: "40%",
                        transform: "translateY(-50%)",
                      }}
                      className="w-1 rounded-full bg-gray-500"
                    />
                  )}
                </div>
              ))}
              <div className="flex justify-center mt-2 space-x-2">
                <button
                  style={{ color: "#075E54" }}
                  className="rounded-full flex items-center justify-center"
                  onClick={handleAddInput}
                >
                  <FaCirclePlus />
                </button>
                <button
                  style={{ color: "#075E54" }}
                  className="rounded-full flex items-center justify-center"
                  onClick={handleRemoveInput}
                >
                  <FaCircleMinus />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="px-2 mb-2">
          <div
            style={{
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "8px",
              backgroundColor: "#075E54", // Light gray background
              borderRadius: "8px", // Adding slight rounding for a more card-like feel
              position: "relative",
              marginTop: "8px",
              textAlign: "center",
              color: "white", // Equivalent to text-gray-500
              fontSize: "16px", // Equivalent to text-xs and fs-10
            }}
            className="max-h-max px-2"
          >
            wrong response
            <Handle
              style={{
                position: "absolute",
                right: -11,
                top: "50%",
                transform: "translateY(-50%)",
              }}
              id="bs"
              type="source"
              position={Position.Right}
              className="w-1 rounded-full bg-gray-500"
            />
          </div>
        </div>
      </div>

      <Handle
        id="a"
        type="target"
        position={Position.Left}
        className="w-1 rounded-full bg-slate-500"
      />
    </div>
  );
}

export default MessageNode;
