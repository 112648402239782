import React from 'react';
import ReactApexChart from 'react-apexcharts';

const dummyData = {
  "Jan": {
    "weekly": Array.from({ length: 5 }, (_, i) => Math.floor(Math.random() * 100)),
    "monthly": Array.from({ length: 12 }, () => Math.floor(Math.random() * 200)),
    "pie": [44, 55, 13, 43, 22]
  },
  "Feb": {
    "weekly": Array.from({ length: 5 }, (_, i) => Math.floor(Math.random() * 100)),
    "monthly": Array.from({ length: 12 }, () => Math.floor(Math.random() * 200)),
    "pie": [33, 45, 15, 32, 25]
  }
};

const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const ApexChart = ({ type, series, categories, title, labels, height }) => {
  const options = {
    chart: {
      type: type || 'line',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["black"],
      },
    },
    xaxis: {
      categories: categories || [],
      position: 'bottom',
      labels: {
        style: {
          colors: Array(categories ? categories.length : 0).fill("#FFFFFF"),
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,  // Adjust according to your data
      tickAmount: 5,
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    title: {
      text: title || 'Chart Title',
      floating: false,
      align: 'left',
      style: {
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: 'bold',
      },
    },
    labels: labels || [],
  };

  return (
    <div id="chart-container" style={{ position: 'relative', height: height || '100%' }}>
      <ReactApexChart 
        options={options} 
        series={series || []} 
        type={type || 'line'} 
        height={height || '100%'} 
      />
    </div>
  );
};

const DashChart = () => {
  const dailyData = dummyData['Jan'].weekly; // Example data for daily chart
  const monthlyData = dummyData['Jan'].monthly; // Example data for monthly chart
  const pieData = dummyData['Jan'].pie; // Example data for pie chart

  return (
    <div className="container-fluid p-3">
      <div className="row g-3">
        {/* Daily Customer Visits (Bar Chart) */}
        <div className="col-12 col-md-4 d-flex align-items-stretch mb-3">
          <div className="card text-center shadow" style={{ backgroundColor: "#A66CFF", borderRadius: "10px", width: '100%' }}>
            <div className="card-body d-flex flex-column justify-content-center">
              <ApexChart 
                type="bar" 
                series={[{ name: 'Daily Visits', data: dailyData }]} 
                categories={weeks} // Use weeks for x-axis
                title="Daily Customer Visits" 
                height="350px" // Use fixed height for better responsiveness
              />
            </div>
          </div>
        </div>
        {/* Monthly Customer Visits (Line Chart) */}
        <div className="col-12 col-md-4 d-flex align-items-stretch mb-3">
          <div className="card text-center shadow" style={{ backgroundColor: "#1E1E1E", borderRadius: "10px", width: '100%' }}>
            <div className="card-body d-flex flex-column justify-content-center">
              <ApexChart 
                type="line" 
                series={[{ name: 'Monthly Visits', data: monthlyData }]} 
                categories={months} 
                title="Monthly Customer Visits" 
                height="350px" // Use fixed height for better responsiveness
              />
            </div>
          </div>
        </div>
        {/* Customer Distribution (Pie Chart) */}
        <div className="col-12 col-md-4 d-flex align-items-stretch mb-3">
          <div className="card text-center shadow" style={{ backgroundColor: "#FF7F3F", borderRadius: "10px", width: '100%' }}>
            <div className="card-body d-flex flex-column justify-content-center">
              <ApexChart 
                type="pie" 
                series={pieData} 
                labels={['Region 1', 'Region 2', 'Region 3', 'Region 4', 'Region 5']} // Labels for Pie chart
                title="Customer Distribution" 
                height="350px" // Use fixed height for better responsiveness
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashChart;
