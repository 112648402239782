import React, { useEffect, useState } from "react";
import loginStyle from "./Login.module.css";
import { BsPerson, BsLock } from "react-icons/bs";
import Logo from "../components/images/aws_logo.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { ApiEndPoint, decodeToken } from "../components/utils/utlis.js";
import Footer from "../Term And Services/footer.jsx";
import Footer1 from "../Term And Services/footer1.jsx";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const jwtToken = sessionStorage.getItem("jwtToken");
  const { userId } = decodeToken(jwtToken);

  useEffect(() => {
    const { isExpired } = decodeToken(jwtToken);
    console.log("IS EXPIRED:", isExpired);
    if (!isExpired) {
      if (isExpired) {
        navigate("/");
      } else {
       
          navigate("/dashboard", {
            replace: true,
          }); 
      }
    }
  }, [jwtToken, navigate]);

  const handleNavigation = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${ApiEndPoint}/login`, {
        email,
        password,
      });
      console.log("USER Data:", response.data);
      if (response.status === 200) {
        console.log("USER success:", response);
        const { token } = response.data;
        login(token, response.data.userId, response.data.username,response.data.role);
       
          navigate("/dashboard", {
            replace: true,
          });
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Invalid credentials. Please try again later.");
    }
  };

  return (
    <div style={{marginTop:36}} className={loginStyle["containerFluid"] + " container-fluid"}>
      <img src={Logo} alt="Logo" className={loginStyle.logo} />
      <div className={loginStyle.rowContainer + " row  text-center"}>
        <div
          className={
            loginStyle["login-form-1"] + " col-sm-12 col-md-6 col-lg-6"
          }
        ></div>
        <div
          className={
            loginStyle["login-form-2"] + " col-sm-12 col-md-6 col-lg-6"
          }
        >
          <div className={loginStyle["text-center"]}>Sign In</div>
          <form>
            <div className={" text-start"}>
              <label className={loginStyle["form-label"]}>Username</label>
              <div className={"input-group mb-3 bg-soft-light rounded-2"}>
                <span className={loginStyle.icons + " input-group-text"}>
                  <BsPerson />
                </span>
                <input
                  aria-invalid="false"
                  className={
                    loginStyle["form-control-1"] +
                    " form-control-lg form-control"
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  type="text"
                />
              </div>
            </div>
            <div className="mb-3 text-start">
              <label className={loginStyle["form-label"]}>Password</label>
              <div className=" input-group mb-3 bg-soft-light rounded-2 ">
                <span className={loginStyle.icons + " input-group-text"}>
                  <BsLock />
                </span>
                <input
                  aria-invalid="false"
                  className="form-control-lg form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  type="password"
                />
              </div>
            </div>
            <div className="d-grid">
              <button
                className={
                  loginStyle["btn-color"] +
                  " btn btn-primary d-block w-100 mt-3"
                }
                type="submit"
                onClick={handleNavigation}
              >
                Sign in
              </button>
            </div>
          </form>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
          <div className="mt-3">
            {" "}
            {/* Centering Forget password link */}
            <Link className="text-muted font-size-13" to="/ForgetPassword">
              Forgot password?
            </Link>
          </div>
          <p className="mt-3" style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
          <p><Footer /></p> <p style={{marginLeft:20}}><Footer1 /></p>
        </p>
        </div>
       
      </div>
    </div>
  );
};
export default Login;
