// src/components/TermsOfService.js

import React from "react";
import "./TermAndServics.css";
const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service for AWS Real Estate WhatsApp Chatbot</h1>
      <p>
        Welcome to AWS Real Estate's WhatsApp Chatbot. By accessing or using our
        chatbot, you agree to comply with and be bound by these Terms of Service
        ("Terms"). Please read these Terms carefully. If you do not agree with
        these Terms, please do not use our chatbot.
      </p>

      <h2>Chatbot Services</h2>
      <p>
        AWS Real Estate's WhatsApp Chatbot provides users with information and
        services related to inquiries, and other related services.
      </p>

      <h2>User Obligations</h2>
      <p>By using our chatbot, you agree to:</p>
      <ul>
        <li>Provide accurate and complete information when prompted.</li>
        <li>Use the chatbot for lawful purposes only.</li>
        <li>
          Not engage in any activity that interferes with or disrupts the
          chatbot's functionality.
        </li>
        <li>Respect the privacy and rights of other users.</li>
      </ul>

      <h2>Login Dialog & App Details</h2>
      <h3>Login Dialog</h3>
      <p>
        To access certain features of our chatbot, you may be required to log in
        using your phone number or email. This is to authenticate your identity
        and ensure secure access to our services.
      </p>

      <h3>App Details</h3>
      <ul>
        <li>
          <strong>App Name:</strong> AWS Real Estate WhatsApp Chatbot
        </li>
        <li>
          <strong>Developer:</strong> AWS Real Estate
        </li>
        <li>
          <strong>Purpose:</strong> To assist users with inquiries and through a
          conversational interface.
        </li>
        <li>
          <strong>Data Collected:</strong> Personal and non-personal information
          as outlined in our Privacy Policy.
        </li>
        <li>
          <strong>Data Usage:</strong> As specified in the "How We Use Your
          Information" section of our Privacy Policy.
        </li>
      </ul>

      <h2>Use of Information</h2>
      <p>
        Any information provided by you through the chatbot will be used in
        accordance with our Privacy Policy. We collect and use your information
        to provide and improve our services, communicate with you, and comply
        with legal obligations.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        All content and materials provided through the chatbot, including text,
        graphics, logos, and software, are the intellectual property of AWS Real
        Estate or its licensors. You are granted a limited, non-exclusive,
        non-transferable license to access and use the chatbot for personal,
        non-commercial purposes only.
      </p>

      <h2>Disclaimers</h2>
      <p>
        The chatbot is provided on an "as is" and "as available" basis. AWS Real
        Estate makes no representations or warranties of any kind, express or
        implied, regarding the operation or availability of the chatbot.
      </p>
      <p>
        AWS Real Estate does not warrant that the chatbot will be error-free,
        uninterrupted, or free of viruses or other harmful components.
      </p>
      <p>
        Information provided through the chatbot is for informational purposes
        only and should not be considered professional or legal advice.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, AWS Real Estate shall not be
        liable for any indirect, incidental, special, consequential, or punitive
        damages, or any loss of profits or revenues, whether incurred directly
        or indirectly, or any loss of data, use, goodwill, or other intangible
        losses, resulting from:
      </p>
      <ul>
        <li>Your use or inability to use the chatbot.</li>
        <li>
          Any unauthorized access to or use of our servers and/or any personal
          information stored therein.
        </li>
        <li>
          Any interruption or cessation of transmission to or from the chatbot.
        </li>
      </ul>

      <h2>Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless AWS Real Estate, its
        affiliates, officers, directors, employees, agents, and licensors from
        and against any claims, liabilities, damages, losses, and expenses,
        including reasonable attorneys' fees, arising out of or in any way
        connected with your access to or use of the chatbot.
      </p>

      <h2>Changes to These Terms</h2>
      <p>
        We may update these Terms from time to time. We will notify you of any
        changes by posting the new Terms on our website and, if applicable,
        through our chatbot. Your continued use of the chatbot after any changes
        signifies your acceptance of the updated Terms.
      </p>

      <h2>Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of the UAE, without regard to its conflict of law principles.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms or our services,
        please contact us at:
      </p>
      <ul>
        <li>
          <strong>AWS Real Estate</strong>
        </li>
        <li>Email: info@aws-realestate.com</li>
        <li>Phone: +971 58 519 0907</li>
        <li>Address: 1602, H Hotel, Sheikh Zayed Road, Dubai, U.A.E</li>
      </ul>
    </div>
  );
};

export default TermsOfService;
