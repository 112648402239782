import React, { useState } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Admin from "../Admin/Admin";
import Dashboard from "../Main page/Dashboard";
import Dashboard2 from "../Main page/Dashboard2";
import Sendmessage from "../Main page/Sendmessage";
import Messagetemplate from "../Main page/Messagetemplate";
import Broadcast from "../Main page/Broadcast";
import Temp_Build from "../Main page/Temp_Bulid";
import ChatUI from "../ChatUI/Chat";
import Chat from "../pages/chat/Chat";
import Login from "../pages/Auth/Loging";
import App from "../Chatbot/App";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import TermAndservics from "../pages/Term And Services/TermAndSevics";
import PolicyPage from "../pages/Term And Services/PolicyPage";
import { useAuth } from "../pages/Auth/AuthContext";
import { decodeToken } from "../pages/components/utils/utlis";

const data = [
  {
    name: "bulk_marketing",
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "1134894744511050",
  },
  {
    name: "real_estate",
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "954662462701367",
  },
  {
    name: "seasonal_sromotion",
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "8333129060046860",
  },
  {
    name: "hello_world",
    language: "en_US",
    status: "APPROVED",
    category: "UTILITY",
    id: "353350330780349",
  },
];

const Routee = () => {
  const PrivateRoute = ({ element, ...rest }) => {
    const { token: authToken } = useAuth();
    const jwtToken = sessionStorage.getItem("jwtToken");
    const { isExpired } = decodeToken(jwtToken);
    let decodedToken = null;

    if (jwtToken) {
      const decoded = decodeToken(jwtToken);
      decodedToken = decoded ? decoded.decodedToken : null;
    }

    const token = authToken || decodedToken;

    return token && !isExpired ? element : <Navigate to="/" />;
  };
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/dashboard2" element={<PrivateRoute element={<Dashboard2 />} />} />
          <Route path="/contacts" element={<PrivateRoute element={<ChatUI />} />} />
          <Route path="/chatbot" element={<PrivateRoute element={<App />} />} />
          <Route path="/admin" element={<PrivateRoute element={<Admin />} />} />
          <Route path="/sendmessage" element={<PrivateRoute element={<Sendmessage />} />} />
          <Route path="/messagetemplate" element={<PrivateRoute element={<Messagetemplate />} />} />
          <Route path="/chat" element={<PrivateRoute element={<Chat />} />} />
          <Route path="/broadcast" element={<PrivateRoute element={<Broadcast />} />} />
          <Route path="/Perview" element={<PrivateRoute element={<Temp_Build />} />} />
          <Route path="/TermAndservics" element={<TermAndservics />} />
          <Route path="/PolicyPage" element={<PolicyPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default Routee;
