import React from "react";
import rightChatstyle from "./Chat.module.css";
import { formatTimestamp, messageTime, splitSenderName } from "../components/utils/utlis";
 // Make sure to import your CSS module
 import styles from "./widgets/dynamicDocument.module.css"; 
export default function RightChatTextWidget({ Name, time, message, botImage }) {
  const date = new Date(time);
  const timeDate = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const base64ToUrl = (base64String) => `data:image/jpeg;base64,${base64String}`;

  return (
    <div className={rightChatstyle["chat-message-right"] + " pb-4 d-flex"}>
      <center>
        <div className={styles[`avatar-right p2`]}>
        <img
            alt="Admin"
            src={botImage? botImage : "https://bootdey.com/img/Content/avatar/avatar1.png"}
            className="rounded-circle"
            width={40}
            height={40}
            title={Name}
          />
          <div className="text-center text-wrap sender-name">
            {splitSenderName(Name)}
            <br />
            {formatTimestamp(time)}
          </div>
          <div className="text-muted text-sm-start small text-wrap">
            {/* Additional content if needed */}
          </div>
        </div>
      </center>
      <div
        className="flex-shrink-1 bg-light rounded py-2 px-1"
        style={{
          wordWrap: "break-word",
          maxWidth: "70%",
        }}
      >
        {message}
        <br />
      </div>
    </div>
  );
}

