import React from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import Home from "./pages/Home/Home";
import Login from "./pages/Auth/Loging";

import ChatUI from "./ChatUI//Chat.jsx";
import { AuthProvider, useAuth } from "./pages/Auth/AuthContext";
import ContactForm from "./pages/ContactForm/ContactForm";
import Chat from "./pages/chat/Chat.js";
import Routee from "./Routes/Routee.jsx";

const PrivateRoute = ({ element, ...rest }) => {
  const { token } = useAuth();
  return token ? element : <Navigate to="/" />;
};

const App = () => {
  return (
    <div>
      <AuthProvider>
        <Routee />
      </AuthProvider>
    </div>
  );
};

export default App;
