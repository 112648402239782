// src/components/PrivacyPolicy.js

import React from "react";
import "./policy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy for AWS Real Estate WhatsApp Chatbot</h1>
      <p>
        Welcome to AWS Real Estate's WhatsApp Chatbot. This privacy policy
        outlines how we collect, use, and protect your personal information when
        you interact with our chatbot. By using our chatbot, you agree to the
        terms of this privacy policy.
      </p>

      <h2>Information We Collect</h2>
      <h3>Personal Information</h3>
      <p>
        When you interact with our WhatsApp Chatbot, we may collect the
        following types of personal information:
      </p>
      <ul>
        <li>
          <strong>Name:</strong> To personalize your experience.
        </li>
        <li>
          <strong>Phone Number:</strong> To identify and communicate with you.
        </li>
        <li>
          <strong>Email Address:</strong> If provided, for further communication
          and services.
        </li>
      </ul>

      <h3>Non-Personal Information</h3>
      <p>
        We may also collect non-personal information that does not directly
        identify you. This includes:
      </p>
      <ul>
        <li>
          <strong>Usage Data:</strong> Information about how you use the
          chatbot.
        </li>
        <li>
          <strong>Technical Data:</strong> Information about your device and
          network connection.
        </li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>We use the collected information for the following purposes:</p>
      <ul>
        <li>
          <strong>Personalization:</strong> To customize your experience based
          on your preferences.
        </li>
        <li>
          <strong>Improvement:</strong> To analyze usage patterns and improve
          the functionality and user experience of our chatbot.
        </li>
        <li>
          <strong>Compliance:</strong> To comply with legal obligations and
          protect our rights.
        </li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties except as described in this privacy policy. We may
        share your information with:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> Third-party vendors who assist us
          in operating our chatbot and providing services to you.
        </li>
        <li>
          <strong>Legal Authorities:</strong> If required by law or to protect
          our rights.
        </li>
      </ul>

      <h2>Data Security</h2>
      <p>
        We implement appropriate security measures to protect your personal
        information from unauthorized access, alteration, disclosure, or
        destruction. However, no method of transmission over the internet or
        electronic storage is completely secure, and we cannot guarantee
        absolute security.
      </p>

      <h2>Login Dialog & App Details</h2>
      <h3>Login Dialog</h3>
      <p>
        When you log in to use our WhatsApp Chatbot, you may be required to
        provide your phone number or email. This information is used to
        authenticate your identity and ensure secure access to our services.
      </p>

      <h3>App Details</h3>
      <ul>
        <li>
          <strong>App Name:</strong> AWS Real Estate WhatsApp Chatbot
        </li>
        <li>
          <strong>Developer:</strong> AWS Real Estate
        </li>
        <li>
          <strong>Purpose:</strong> To assist users with inquiries and through a
          conversational interface.
        </li>
        <li>
          <strong>Data Collected:</strong> Personal and non-personal information
          as outlined above.
        </li>
        <li>
          <strong>Data Usage:</strong> As specified in the "How We Use Your
          Information" section.
        </li>
      </ul>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time. We will notify you
        of any changes by posting the new policy on our website and, if
        applicable, through our chatbot. Your continued use of the chatbot after
        any changes signifies your acceptance of the updated policy.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this privacy policy or our
        data practices, please contact us at:
      </p>
      <ul>
        <li>
          <strong>AWS Real Estate</strong>
        </li>
        <li>Email: info@aws-realestate.com</li>
        <li>Phone: +971 58 519 0907</li>
        <li>Address: 1602, H Hotel, Sheikh Zayed Road, Dubai, U.A.E</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
