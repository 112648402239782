import React, { useEffect, useState } from "react";
import loginStyle from "./Login.module.css";
import { BsPerson, BsLock } from "react-icons/bs";
import Logo from "../components/images/aws_logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { TbPasswordFingerprint } from "react-icons/tb";
import { ApiEndPoint } from "../components/utils/utlis";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [semail, setSEmail] = useState(true);
  const [scode, setSCode] = useState(false);
  const [spass, setSpass] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);

  const handlecode = async (event) => {
    event.preventDefault();
    setLoadingCode(true);
    try {
      const response = await axios.get(`${ApiEndPoint}/checkCode/${code}`);
      console.log("response", response);
      if (response.status === 200) {
        setSEmail(false);
        setSCode(false);
        setSpass(true);
        setErrorMessage("");
      } else {
        setErrorMessage("4 Digit verification code is incorrect");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("4 Digit verification code is incorrect. Please try again.");
    } finally {
      setLoadingCode(false);
    }
  };

  const handleemail = async (event) => {
    event.preventDefault();
    setLoadingEmail(true);
    try {
      const response = await axios.get(`${ApiEndPoint}/admin/${email}`);
      if (response.status === 200) {
        setSEmail(false);
        setSCode(true);
        setSpass(false);
        setErrorMessage("");
      } else {
        setErrorMessage("Email address does not exist. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Email address does not exist. Please try again.");
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleNavigation = async (event) => {
    event.preventDefault();
    setLoadingPassword(true);
    try {
      const response = await axios.put(`${ApiEndPoint}/passUpdate`, {
        email,
        password,
      });
      console.log("USER Data:", response.data);
      if (response.status === 200) {
        navigate("/", {
          replace: true,
        });
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("User are not found. Please try again.");
    } finally {
      setLoadingPassword(false);
    }
  };

  return (
    <div
      style={{ marginTop: 23 }}
      className={loginStyle["containerFluid"] + " container-fluid"}
    >
      <img src={Logo} alt="Logo" className={loginStyle.logo} />
      <div className={loginStyle.rowContainer + " row  text-center"}>
        <div
          className={
            loginStyle["login-form-1"] + " col-sm-12 col-md-6 col-lg-6"
          }
        ></div>
        <div
          className={
            loginStyle["login-form-2"] + " col-sm-12 col-md-6 col-lg-6"
          }
        >
          <div className={loginStyle["text-center"]}>Forget Password</div>
          {semail && (
            <form style={{ paddingTop: 105, paddingBottom: 105 }}>
              <div className={" text-start"}>
                <label className={loginStyle["form-label"]}>Username</label>
                <div className={"input-group mb-3 bg-soft-light rounded-2"}>
                  <span className={loginStyle.icons + " input-group-text"}>
                    <BsPerson />
                  </span>
                  <input
                    aria-invalid="false"
                    className={
                      loginStyle["form-control-1"] +
                      " form-control-lg form-control"
                    }
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    type="email"
                  />
                </div>
                {errorMessage && <p className="text-danger" style={{ fontSize: 14 }}>{errorMessage}</p>}
              </div>
              <div className="d-grid">
                <button
                  className={
                    loginStyle["btn-color"] +
                    " btn btn-primary d-block w-100 mt-3"
                  }
                  type="submit"
                  onClick={handleemail}
                  disabled={loadingEmail}
                >
                  {loadingEmail ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Search"}
                </button>
              </div>
            </form>
          )}
          {scode && (
            <form style={{ paddingTop: 105, paddingBottom: 78 }}>
              <div className={" text-start"}>
                <label className={loginStyle["form-label"]}>
                  Verification Code
                </label>
                <div className={"input-group mb-2 bg-soft-light rounded-2"}>
                  <span className={loginStyle.icons + " input-group-text"}>
                    <TbPasswordFingerprint />
                  </span>
                  <input
                    aria-invalid="false"
                    className={
                      loginStyle["form-control-1"] +
                      " form-control-lg form-control"
                    }
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    id="email"
                    name="email"
                    placeholder="Enter Verification Code"
                    type="number"
                  />
                </div>
                <p className="text-sucess" style={{ fontSize: 14 }}>
                  {" "}
                  4-Digit Verification code sent on your email{" "}
                </p>
                {errorMessage && <p className="text-danger" style={{ fontSize: 14 }}>{errorMessage}</p>}
              </div>
              <div className="d-grid">
                <button
                  className={
                    loginStyle["btn-color"] +
                    " btn btn-primary d-block w-100 mt-3"
                  }
                  type="submit"
                  onClick={handlecode}
                  disabled={loadingCode}
                >
                  {loadingCode ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Verify Code"}
                </button>
              </div>
            </form>
          )}
          {spass && (
            <form style={{ paddingTop: 105, paddingBottom: 105 }}>
              <div className="text-start">
                <label className={loginStyle["form-label"]}>Password</label>
                <div className=" input-group mb-3 bg-soft-light rounded-2 ">
                  <span className={loginStyle.icons + " input-group-text"}>
                    <BsLock />
                  </span>
                  <input
                    aria-invalid="false"
                    className="form-control-lg form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    placeholder="Enter Password"
                    type="password"
                  />
                </div>
                {errorMessage && <p className="text-danger" style={{ fontSize: 14 }}>{errorMessage}</p>}
              </div>
              <div className="d-grid">
                <button
                  className={
                    loginStyle["btn-color"] +
                    " btn btn-primary d-block w-100 mt-3"
                  }
                  type="submit"
                  onClick={handleNavigation}
                  disabled={loadingPassword}
                >
                  {loadingPassword ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Change Password"}
                </button>
              </div>
            </form>
          )}
          
        </div>
      </div>
     
    </div>
  );
};
export default ForgetPassword;
