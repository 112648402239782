import React, { useContext, useEffect, useRef, useState } from "react";
import UserListWidget from "./UserListWidget";
import ChatHeader from "./ChatHeader";
import LeftChatTextWidget from "../LeftChatTextWidget";
import chatstyle from "../Chat.module.css";
import RightChatTextWidget from "../RightChatTextWidget";
import axios from "axios";
import { useAuth } from "../../Auth/AuthContext";
import { ApiEndPoint, base64ToUrl, decodeToken, mondayLogoImage } from "../../components/utils/utlis";
import { UserContext } from "./userContext";
import mondayLogo from "../../components/images/monLogo.png";

import botImage from "../../components/assets/icons/bot.png";
import { SiGooglemessages } from "react-icons/si";
import ChatInput from "./ChatInput"; // Import ChatInput
import DynamicImage from "./dynamicImage";
import DynamicDocument from "./dynamicDocuments";
import DynamicAudio from "./dynamicAudio";
import { Buffer } from 'buffer';
const scrollToBottom = (ref) => {
  if (ref.current) {
    ref.current.scrollTop = ref.current.scrollHeight;
  }
};
export default function ChatField({ user,isCollapsed }) {
  const [userData, setUserData] = useState([]);
  const [replyText, setReplyText] = useState("");
  const [loading, setLoading] = useState(true);
  
  const [selectedUser, setSelectedUser] = useState(null);

  const [file, setFile] = useState(null);
  let [messagesArray, setMessagesArray] = useState([]);
  const messagesEndRef = useRef(null);
  const [isInteracting, setIsInteracting] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const mouseMoveTimer = useRef(null);
  const previousMessagesLength = useRef(0);
 const jwtToken = sessionStorage.getItem("jwtToken");
 const  {decodedToken}= decodeToken(jwtToken)
 const { userId } = decodedToken;


 
useEffect(()=>{
  setLoading(true);
  setMessagesArray([])
  setUserData([])
  setSelectedUser(user)
},[user])
  useEffect(() => {
    setSelectedUser(user);
    const handleScroll = () => {
      if (!isInteracting && !isHovering && !isMouseMoving) {
        const currentMessagesLength = messagesArray.length;
        if (currentMessagesLength > previousMessagesLength.current) {
          scrollToBottom();
          previousMessagesLength.current = currentMessagesLength;
        }
      }
    };

    const handleMouseMove = () => {
      setIsMouseMoving(true);
      clearTimeout(mouseMoveTimer.current);
      mouseMoveTimer.current = setTimeout(() => {
        setIsMouseMoving(false);
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isInteracting, isHovering, isMouseMoving, userData, messagesArray, user]);

  const handleInteractionStart = () => {
    setIsInteracting(true);
  };

  const handleInteractionEnd = () => {
    setIsInteracting(false);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMouseMove = () => {
    setIsMouseMoving(true);
    clearTimeout(mouseMoveTimer.current);
    mouseMoveTimer.current = setTimeout(() => {
      setIsMouseMoving(false);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleInteractionStart);
    window.addEventListener("touchstart", handleInteractionStart);
    window.addEventListener("mouseup", handleInteractionEnd);
    window.addEventListener("touchend", handleInteractionEnd);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousedown", handleInteractionStart);
      window.removeEventListener("touchstart", handleInteractionStart);
      window.removeEventListener("mouseup", handleInteractionEnd);
      window.removeEventListener("touchend", handleInteractionEnd);
      window.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(mouseMoveTimer.current);
    };
  }, []);

  // useEffect(() => {
  //   scrollToBottom();
  //   previousMessagesLength.current = messagesArray.length;
  // }, [
  //   userData,
  //   isInteracting,
  //   isHovering,
  //   isMouseMoving,
  //   messagesArray.length,
  // ]);

  const handleSendMessage = async (message) => {
   
    const currentTime = new Date().toLocaleTimeString(); // Get current time
    const newMessage = {
      message_text: message,
      timestamp: currentTime,
      sent_by: "Admin",
    };
    setMessagesArray((prevMessages) => [...prevMessages, newMessage]);
  
    try {
      // const formData = new FormData();
      // formData.append('userId', userId);
      // formData.append('clientId', selectedUser.id);
      // formData.append('recipientNumber', selectedUser.phone);
      const phone= selectedUser.phone;
      // formData.append('message', message);
  
      // Check if a file is selected and its size is less than 16MB (16777216 bytes
  
      const response = await axios.post(
        `${ApiEndPoint}/sendReply`,
        { phone, message, userId },
       
      );
  
      console.log("File and/or message sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  
  const handleSendImage = async (file, caption) => {
    const phone = localStorage.getItem("phone");
    const currentTime = new Date().toLocaleTimeString(); // Get current time
    const newMessage = {
      message_text: caption || "Sent an image",
      timestamp: currentTime,
      sent_by: "Admin",
      files: file,
    };
    setMessagesArray((prevMessages) => [...prevMessages, newMessage]);
  
    try {
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('clientId', selectedUser.id);
      formData.append('recipientNumber', phone);
      formData.append('message', caption || "Sent an image");
      formData.append('file', file);
  
      const response = await axios.post(
        `${ApiEndPoint}/send-whatsapp-media`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      console.log("Image sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending image:", error);
    }
  };
  
  const handleSendDoc = async (file, caption) => {
    const phone = localStorage.getItem("phone");
    const currentTime = new Date().toLocaleTimeString(); // Get current time
    const newMessage = {
      message_text: caption || "Sent a document",
      timestamp: currentTime,
      sent_by: "Admin",
      files: file,
    };
    setMessagesArray((prevMessages) => [...prevMessages, newMessage]);
  
    try {
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('clientId', selectedUser.id);
      formData.append('recipientNumber', phone);
      formData.append('message', caption || "Sent a document");
      formData.append('file', file);
  
      const response = await axios.post(
        `${ApiEndPoint}/send-whatsapp-media`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      console.log("Document sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending document:", error);
    }
  };
  
  const handleSendVoice = async (audioBlob) => {
    const phone = localStorage.getItem("phone");
    const currentTime = new Date().toLocaleTimeString();

    try {
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('clientId', selectedUser.id);
        formData.append('recipientNumber', phone);
        formData.append('message', "Sent a voice message");

        // Set the correct MIME type based on the audioBlob type
        let mimeType = '';
        if (audioBlob.type === 'audio/mpeg') {
            mimeType = 'audio/mpeg';
        } else if (audioBlob.type === 'audio/mp4') {
            mimeType = 'audio/mp4';
        } else if (audioBlob.type === 'audio/ogg; codecs=opus') {
            mimeType = 'audio/ogg; codecs=opus';
        } else {
            console.error('Unsupported audio format.');
            return;
        }
        formData.append('file', new File([audioBlob], "voice_message." + mimeType.split('/')[1], { type: mimeType }));

        const response = await axios.post(
            `${ApiEndPoint}/send-whatsapp-media`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        console.log("Voice message sent successfully:", response.data);
    } catch (error) {
        console.error("Error sending voice message:", error);
    }
};

  




  useEffect(() => {
    if (selectedUser) {
      const fetchUserData = async () => {
        try {
          
          const response = await fetch(
            `${ApiEndPoint}/user/${selectedUser.id}`
          );
          const data = await response.json();

          if (response.ok) {
            setUserData(data.data);
            const parsedMessagesArray = data.data?.[0]?.messages
              ? JSON.parse(`[${data.data[0].messages}]`)
              : [];
            setMessagesArray(parsedMessagesArray);
          } else {
            console.error("Failed to fetch user data:", data.error);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      const interval = setInterval(fetchUserData, 3000);

      return () => clearInterval(interval); // Return early if selectedUser is null or undefined
    }
  }, [messagesArray, selectedUser, userData,user]);
  messagesArray = userData?.[0]?.messages
    ? JSON.parse(`[${userData[0].messages}]`)
    : [];
 
    if (!selectedUser && userData) {
      return (
        <div
          className={`${chatstyle["no-user-selected"]} d-flex justify-content-center align-items-center`}
          style={{
            height: "100%",
            width: "100%",
            maxWidth: "100%", // Ensure the container doesn't exceed the screen width
            maxHeight: "100%", // Ensure container remains within bounds vertically
            background: "white",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            overflow: "hidden", // Prevent overflow in both directions
          }}
        >
          <div className="col-lg-9 col-md-7 d-none d-md-block d-lg-block">
            <div className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100">
              <SiGooglemessages className="fs-1" />
              <div>
                <h4>Conversation detail</h4>
                <p>Select a contact to view conversation</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%', 
        maxHeight: '100vh',  // Ensure it never grows beyond the viewport height
        width: '100%',
        maxWidth: '100%', // Keep within the screen width bounds
        overflow: 'hidden',
          // Prevent overflow from causing the container to grow
      }}>
        <div className="chatfield border-bottom  d-lg-block">
          <div className="d-flex align-items-center">
            <div
              className="pl-3 row container-fluid d-flex flex-grow-1"
              style={{ maxHeight: "11vh", overflowY: "auto" }}
            >
              <ChatHeader
                name={selectedUser ? selectedUser.name : "Name"}
                phone={selectedUser ? selectedUser.phone : ""}
                profilePic={selectedUser ? selectedUser.profilepic : ""}
                color_code={selectedUser?.color_code}
              />
            </div>
          </div>
        </div>
    
        <div
          className="pl-5 py-4 mb-2 chat-messages"
          style={{
            height: "59vh",
            flexGrow: 1,
            width: "100%",
            maxWidth: "100%", // Prevent overflow horizontally
            overflowX: "hidden", // Ensure no horizontal scrolling
            overflowY: "scroll", // Allow vertical scrolling only
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {loading ? (
            <div className={`${chatstyle["loading-indicator"]}`}>
              <div className={`${chatstyle["spinner"]}`}></div>
            </div>
          ) : (
            userData &&
            userData.map((user, index) =>
              messagesArray.map((message, messageIndex) => {
                if (message.message_text) {
                  return message.sent_by === "Client" ? (
                    <LeftChatTextWidget
                      key={messageIndex}
                      Name={selectedUser.name}
                      message={message.message_text}
                      time={message.timestamp}
                      profilePic={selectedUser ? selectedUser.profilepic : ""}
                      color_code={selectedUser ? selectedUser?.color_code : ""}
                    />
                  ) : message.sent_by === "Admin" ? (
                    <RightChatTextWidget
                      key={messageIndex}
                      Name={message.admin_name}
                      message={message.message_text}
                      time={message.timestamp}
                      botImage={base64ToUrl(message.profilePIc)}
                    />
                  ) : message.sent_by === "monday" ? (
                    <RightChatTextWidget
                      key={messageIndex}
                      Name="Monday"
                      message={message.message_text}
                      time={message.timestamp}
                      botImage={mondayLogo}
                    />
                  ) : (
                    <RightChatTextWidget
                      key={messageIndex}
                      Name={"Bot"}
                      message={message.message_text}
                      time={message.timestamp}
                      botImage={botImage}
                    />
                  );
                } else if (message.files) {
                  const file = JSON.parse(message.files); // Assuming file is a JSON object
                  if (file && file.filetype) {
                    if (
                      file.filetype === "image/jpeg" ||
                      file.filetype === "image/png"
                    ) {
                      return (
                        <DynamicImage
                          key={messageIndex}
                          mimeType={file.filetype}
                          file_id={file.file_id}
                          position={
                            message.sent_by === "Client" ? "left" : "right"
                          }
                          timestamp={message.timestamp}
                          fileName={file.filename}
                          senderName={
                            message.sent_by === "Client"
                              ? ""
                              : message.sent_by === "Admin"
                              ? message.admin_name
                              : message.sent_by === "monday"
                                ? "Monday"
                                : "Bot"
                          }
                          avatar={
                            message.sent_by === "Client"
                              ? selectedUser.profilepic
                              : message.sent_by === "Admin"
                                ? message.profilePIc
                                : message.sent_by === "monday"
                                  ? `${mondayLogoImage}`
                                  : Buffer.from(botImage).toString('base64')
                          }
                        />
                      );
                    } else if (file.filetype.startsWith("application/")) {
                      return (
                        <DynamicDocument
                          key={messageIndex}
                          fileId={file.file_id}
                          mimeType={file.filetype}
                          position={
                            message.sent_by === "Client" ? "left" : "right"
                          }
                          timestamp={message.timestamp}
                          fileName={file.filename}
                          senderName={
                            message.sent_by === "Client"
                              ? ""
                              : message.sent_by === "Admin"
                              ? message.admin_name
                              : message.sent_by === "monday"
                                ? "Monday"
                                : "Bot"
                          }
                          avatar={
                            message.sent_by === "Client"
                              ? selectedUser.profilepic
                              : message.sent_by === "Admin"
                                ? message.profilePIc
                                : message.sent_by === "monday"
                                  ? `${mondayLogoImage}`
                                  : Buffer.from(botImage).toString('base64')
                          }
                        />
                      );
                    } else if (file.filetype.startsWith("audio/")) {
                      return (
                        <DynamicAudio
                          key={messageIndex}
                          fileId={file.file_id}
                          mimeType={file.filetype}
                          position={
                            message.sent_by === "Client" ? "left" : "right"
                          }
                          timestamp={message.timestamp}
                          fileName={file.filename}
                          senderName={
                            message.sent_by === "Client"
                              ? ""
                              : message.sent_by === "Admin"
                              ? message.admin_name
                              : message.sent_by === "monday"
                                ? "Monday"
                                : "Bot"
                          }
                          avatar={
                            message.sent_by === "Client"
                              ? selectedUser.profilepic
                              : message.sent_by === "Admin"
                                ? message.profilePIc
                                : message.sent_by === "monday"
                                  ? `${mondayLogoImage}`
                                  : Buffer.from(botImage).toString('base64')
                          }
                        />
                      );
                    }
                  }
                  return null;
                }
              })
            )
          )}
          <div ref={messagesEndRef} />
        </div>
    
        <ChatInput
          onSendMessage={handleSendMessage}
          onSendImage={handleSendImage}
          onSendDoc={handleSendDoc}
          onSendVoice={handleSendVoice}
        />
      </div>
    );
    
    
  
    
    
    
    

}
