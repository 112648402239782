import React, { useState, useEffect, useRef } from "react";
import { Handle, Position } from "reactflow";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import btn1 from "../../pages/components/images/quick-reply-green.png";
import btn2 from "../../pages/components/images/share-button-green.png";
import { useFlow } from "../FlowProvider";

function UrlButtons({ data, id, selected }) {
  const { flow, onSelectionChange } = useFlow();
  const [inputs, setInputs] = useState(
    data.inputs || [{ text: "", link: "", showText: false, status: false }]
  );
  const [c, setC] = useState(data.count || 1);
  const [nodes, setNodes] = useState();
  const [edges, setEdges] = useState();
  const textInputRefs = useRef([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {}, [selectedLanguage, textInputRefs]);

  useEffect(() => {
    setInputs(data.inputs || [{ text: "", link: "" }]);

    if (flow) {
      const { nodes = [], edges = [] } = flow;
      setNodes(nodes);
      setEdges(edges);

      if (nodes.length > 0 && edges.length > 0) {
        if (onSelectionChange) {
          const lastNodeId = onSelectionChange;
          const matchingEdge = edges.find((edge) => edge.target === lastNodeId);
          if (matchingEdge) {
            const sourceId = matchingEdge.source;
            const matchingNode = nodes.find((node) => node.id === sourceId);
            if (matchingNode) {
              setSelectedLanguage(matchingNode.data.language);
            } else {
              console.log(
                "No matching node found for the source ID:",
                sourceId
              );
            }
          } else {
            console.log(
              "No matching edge found for the last node's ID:",
              lastNodeId
            );
          }
        } else {
          console.log("No 'buttons' type nodes found.");
        }
      } else {
        console.log("Nodes or edges list is empty.");
      }
    }

    setC(data.count || 1);
  }, [data, flow]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedInputs = [...inputs];
    let regex;

    if (selectedLanguage === "en") {
      regex = /^[A-Za-z0-9\s,.;:!?'"\-<>*$\^~`_\[\](){}&@#\+\=%]*$/;
    } else if (selectedLanguage === "ar") {
      regex =
        /^[\u0600-\u06FF\u0750-\u077F0-9\s,.;:!?'"\-<>*$\^~`_\[\](){}&@#\+\=%]*$/;
    } else {
      console.warn(`Unexpected selectedLanguage: ${selectedLanguage}`);
      regex = /.*?/;
    }

    if (name === "text") {
      updatedInputs[index].text = value;

      if (isURL(value)) {
        updatedInputs[index].link = value;
        updatedInputs[index].text = "";
        updatedInputs[index].showText = true;
      } else {
        const inputElement = textInputRefs.current[index];
        if (!regex.test(value)) {
          inputElement.style.borderColor = "red";
          updatedInputs[index].text = "";
          return;
        } else {
          inputElement.style.borderColor = "transparent";
        }
      }
    } else if (name === "link") {
      if (value === "") {
        updatedInputs[index].link = "";
        textInputRefs.current[index]?.focus();
        updatedInputs[index].showText = false;
      } else {
        updatedInputs[index].link = value;
      }
    }

    setInputs(updatedInputs);
    data.inputs = updatedInputs;
  };

  const handleAddInput = () => {
    if (c < 3) {
      const newInputs = [
        ...inputs,
        { text: "", link: "", showText: false, status: false },
      ];
      setInputs(newInputs);
      setC(c + 1);
      data.inputs = newInputs;
      data.count = c + 1;
    }
  };

  const handleRemoveInput = () => {
    if (c > 1) {
      const newInputs = inputs.slice(0, -1);
      setInputs(newInputs);
      setC(c - 1);
      data.inputs = newInputs;
      data.count = c - 1;
    }
  };

  const handleFocus = (index) => {
    const newInputs = inputs.map((input, i) => ({
      ...input,
      status: i === index,
    }));
    setInputs(newInputs);
  };

  const isURL = (str) => {
    const urlRegex =
      /\b((http|https|ftp):\/\/)?(([\w-]+\.)+[\w-]{2,})(:\d{2,5})?(\/[^\s]*)?\b/;
    return urlRegex.test(str);
  };

  return (
    <div
      style={{
        border: selected ? "1px solid #FF7640" : "1px solid #075E54",
        boxShadow: selected
          ? "0 4px 8px rgba(0,0,0,0.2)"
          : "0 2px 4px rgba(0,0,0,0.2)",
        backgroundColor: "#ece4e4",
        borderRadius: "5px",
      }}
      className={`template w-72 shadow-lg rounded-lg`}
    >
      <div className="flex flex-col">
        <div className="px-2 py-1 text-left text-white   font-bold rounded-t-sm bg-[#A66CFF]" style={{
           fontSize: "16px",
        }}>
          🔘 Buttons
        </div>

        <div className="px-2 py-3">
          {inputs.map((input, index) => (
            <div
              className="relative flex flex-col mb-2 p-2"
              key={index}
              style={{
                borderBottomWidth: 1,
                width: "100%",
                borderColor: "#FF7640",
                backgroundColor: "#075E54",
                borderRadius: 10,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onClick={() => handleFocus(index)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={input.showText ? btn2 : btn1}
                  height={10}
                  width={12}
                  style={{ marginRight: 5, color: "#FF7640" }}
                  alt="Button Icon"
                />

                <div style={{ width: "85%", position: "relative" }}>
                  <input
                    type="text"
                    placeholder="Text or URL"
                    style={{
                      width: "100%",
                      height: 25,
                      border: "none",
                      outline: "none",
                     
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "transparent",
                      borderRadius: 4,
                     
                      color: "white",
                      fontSize: "16px",
                      backgroundColor: "#075E54",
                    }}
                    name="text"
                    value={input.text}
                    onChange={(event) => {
                      if (event.target.value.length <= 25) {
                        handleInputChange(index, event);
                      }
                    }}
                    ref={(el) => (textInputRefs.current[index] = el)}
                    onBlur={(e) => {
                      e.target.style.backgroundColor = "#075E54";
                      e.target.style.color = "white";
                    }}
                    onFocus={(e) => {
                      handleFocus(index);
                      e.target.style.backgroundColor = "#075E54";
                      e.target.style.color = "white";
                    }}
                    maxLength={25} // Enforces the maximum character limit directly in the input field
                  />

                  <div
                    style={{
                      color: "white",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "15px",
                      right: "-10px",
                    }}
                  >
                    {input.text.length}/25
                  </div>
                </div>
              </div>

              {input.showText && (
                <input
                  type="text"
                  placeholder="Link"
                  style={{
                    width: "100%",
                    height: 25,
                    border: "none",
                    outline: "none",
                    fontSize: "16px",
                   
                    color: "white",
                    paddingLeft: 15,
                    backgroundColor: "#075E54",
                  }}
                  name="link"
                  value={input.link}
                  onChange={(event) => handleInputChange(index, event)}
                  onBlur={(e) => {
                    e.target.style.backgroundColor = "#075E54";
                    e.target.style.color = "white";
                  }}
                  onFocus={(e) => {
                    handleFocus(index);
                    e.target.style.backgroundColor = "#075E54";
                    e.target.style.color = "white";
                  }}
                />
              )}
              {!input.showText && (
                <Handle
                  id={`b-${index}`}
                  type="source"
                  className="w-1 rounded-full bg-slate-500"
                  position={Position.Right}
                  style={{
                    position: "absolute",
                    right: -10,
                    top: "38%",
                    transform: "translateX(50%)",
                  }}
                />
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-center mb-2 space-x-2">
          <button
            style={{ color: "#075E54" }}
            className="rounded-full flex items-center justify-center"
            onClick={handleAddInput}
          >
            <FaCirclePlus />
          </button>
          <button
            style={{ color: "#075E54" }}
            className="rounded-full flex items-center justify-center"
            onClick={handleRemoveInput}
          >
            <FaCircleMinus />
          </button>
        </div>
      </div>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        className="w-1 rounded-full bg-slate-500"
      />
    </div>
  );
}

export default UrlButtons;
