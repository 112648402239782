import React, { useEffect, useState } from "react";
import "../pages/chat/Chat.module.css";
import UserListWidget from "../pages/chat/widgets/UserListWidget";
import axios from "axios";
import User_profile from "./User_profile";
import {BsSortDown,BsSortUp,} from "react-icons/bs";
import { useRef } from "react";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import { GrContactInfo } from "react-icons/gr";
const ViewAdmin = () => {
  //const Users = useSelector((state) => state.Data.usersdetail);
  const [Users, setUsers] = useState([]);
  //const { token, userId, login } = useAuth();
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(false);
  const filterRef = useRef(null);
 
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, [Users]);
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/admins`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  

 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter users based on search query
  const filteredUsers = Users
  .filter((user) => {
    // Check if user.name is not null and not undefined
    if (user.name && typeof user.name === "string") {
      const lowerCaseName = user.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return lowerCaseName.includes(lowerCaseSearchQuery);
    }
    return false; // Filter out user with null or non-string name
  })
  .sort((a, b) => {
    if (!selectedOption) {
      return a.name.localeCompare(b.name); // Sort A-Z
    }
    if (selectedOption) {
      return b.name.localeCompare(a.name); // Sort Z-A
    }
    return 0; // No sorting if option is not selected
  });

  const userListHeight = `calc(93vh - 130px)`;
  return (
<div
  className="container-fluid p-0 m-0 d-flex"
  style={{
    height: `calc(93vh - 69px)`,
    width: "100%",
    gap: "16px", // Add space between columns
   
  }}
>
  <div
    className="p-2 m-0 bg-white"
    style={{
      borderRadius: "12px", // Rounded corners
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for card effect
      overflow: "hidden", // Prevents overflow from rounded corners
      flex: "0 0 20%", // Fix the width of the first div to 20%
      minWidth: "200px", // Minimum width to prevent shrinking too much
      maxWidth: "300px", // Maximum width to prevent it from expanding too much
    }}
  >
    <div className="d-flex justify-content-between align-items-center">
    <input
          type="search"
          placeholder="Search User"
          className="form-control"
          onChange={handleSearchChange}
          value={searchQuery}
          style={{
            boxShadow:"none"
          }}
          onFocus={(e) => {
            e.target.style.borderColor = "#FF7640"; // Orange border on focus
          }}
          onBlur={(e) => {
            e.target.style.borderColor = "#24D366"; // Green border on unfocus
          }}
        />
      {/* Filter Icon */}
      <div
        className="filter-container"
        style={{ position: "relative" }}
        ref={filterRef}
      >
        {!selectedOption ? (
          <BsSortDown
            onClick={() => {
              setSelectedOption(true);
            }}
            style={{
              width: "30px",
              height: "30px",
              color: "gray",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
          />
        ) : (
          <BsSortUp
            onClick={() => {
              setSelectedOption(false);
            }}
            style={{
              width: "30px",
              height: "30px",
              color: "gray",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
          />
        )}
      </div>
    </div>

    <div
      style={{
        fontSize: "1.1rem",
        maxHeight: userListHeight,
        overflowY: "auto",
        marginTop: "5px",
      }}
    >
      {/* User List Content */}
      {filteredUsers.map((val) => (
        <UserListWidget
          key={val.id}
          user={val}
          onClick={() => handleUserClick(val)}
          picture={val.profilePicture}
          color={val.color_code}
        />
      ))}
    </div>
  </div>

  <div className="text-center d-flex flex-column justify-content-center h-100 w-100"
    style={{
      borderRadius: "10px", // Rounded corners
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for card effect
      overflow: "hidden", // Prevents overflow from rounded corners
      backgroundColor: "#ffffff", // Background color of the card
   
    }}
  >
    {selectedUser? (<User_profile className="Theme3" user={selectedUser} />):(
       <div className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100 w-100">
       <GrContactInfo className="fs-1" />
       <div>
         <h4>Admin Detail</h4>
         <p>Click any Admin to view details</p>
       </div>
     </div>
    )
      
    }
  </div>
</div>


  
  );
};
export default ViewAdmin;
