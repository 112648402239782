import React, { useState, useEffect, useRef } from "react";
import { Handle, Position, useReactFlow } from "reactflow";

function TextNode({ data, id, selected }) {
  const [input, setInput] = useState(data.label || "");
  const [charCount, setCharCount] = useState(0);
  const { setNodes } = useReactFlow();
  const textareaRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(data.language || "en");
  const [isInvalidLanguage, setIsInvalidLanguage] = useState(false);

  useEffect(() => {
    setInput(data.label || "");
    setSelectedLanguage(data.language || "en");
    setCharCount(data.label ? data.label.length : 0);
  }, [data]);

  useEffect(() => {
    autoResizeTextarea();
  }, [input]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 2px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);
    setInput(""); // Clear input when language is changed
    setIsInvalidLanguage(false); // Reset any previous validation
  };

  const handleChange = (event) => {
    let { value } = event.target;
    autoResizeTextarea();

    let regex;
    if (selectedLanguage === "en") {
      regex = /^[A-Za-z0-9\s,.;:!?'"\-<>*$\^~`_\[\](){}&@#\+\=%]*$/;
    } else if (selectedLanguage === "ar") {
      regex = /^[\u0600-\u06FF\u0750-\u077F0-9\s,.;:!?'"\-<>*$\^~`_\[\](){}&@#\+\=%]*$/;
    }
    

    if (!regex.test(value)) {
      setIsInvalidLanguage(true);
      return;
    } else {
      setIsInvalidLanguage(false);
    }

    if (value.length > 550) {
      value = value.slice(0, 550);
    }

    setInput(value);
    setCharCount(value.length);
  };

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: { ...node.data, language: selectedLanguage, label: input },
          };
        }
        return node;
      })
    );
  }, [selectedLanguage, input, setNodes, id]);

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 400)}px`;
    }
  };

  return (
    <div
    style={{
      border: selected ? "1px solid #FF7640" : "1px solid #075E54",
      boxShadow: selected ? "0 4px 8px rgba(0,0,0,0.2)" : "0 2px 4px rgba(0,0,0,0.2)",
      backgroundColor: "#ece4e4",
      borderRadius: "5px",
    }}
      className={`template w-72 shadow-lg rounded-lg ${selected ? "border-solid border-1" : ""}`}
    >
      <div className="flex flex-col">
        <div
          style={{
            background: "#FF7640",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            fontWeight: "bold",
            borderRadius: "0.375rem 0.375rem 0 0",
          }}
          className="px-2 py-1"
        >
          <span style={{ display: "flex", alignItems: "center",fontWeight:"16px" }}>✉️ Message</span>
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            style={{
              background: "white",
              color: "black",
              borderRadius: "0.375rem",
              border: "none",
              outline: "none",
            }}
          >
            <option value="en">English</option>
            <option value="ar">Arabic</option>
          </select>
          <span style={{ fontWeight: "normal", marginLeft: "0.5rem" }}>
            {charCount}/550
          </span>
        </div>

        <div className="px-2 pt-2">
          <div className="w-66 text-xs font-normal text-black">
            <textarea
              ref={textareaRef}
              name="inp"
              value={input}
              onChange={handleChange}
              style={{
                minHeight: "30px",
                maxHeight: "100px",
                width: "100%",
                border: isInvalidLanguage ? "1px solid red" : "none",
                borderRadius: 4,
                outline: "none",
                resize: "none",
                overflowY: "auto",
                fontSize: "12px",
                fontWeight: "500",
                color: "#4b5563",
                backgroundColor: "#ece4e4",
              }}
              placeholder={`Enter Message in ${
                selectedLanguage === "en" ? "English" : "Arabic"
              }`}
            />
          </div>
        </div>
        <div className="px-2 mb-2">
          <div
            style={{
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "8px",
              backgroundColor: "#075E54",
              borderRadius: "8px",
              position: "relative",
              marginTop: "8px",
              textAlign: "center",
              color: "white",
              fontSize: "16px",
            }}
            className="max-h-max px-2"
          >
            wrong response
            <Handle
              style={{
                position: "absolute",
                right: -11,
                top: "50%",
                transform: "translateY(-50%)",
              }}
              id="bs"
              type="source"
              position={Position.Right}
              className="w-1 rounded-full bg-gray-500"
            />
          </div>
        </div>
      </div>

      <Handle
        id="a"
        type="target"
        position={Position.Left}
        className="w-1 rounded-full bg-slate-500"
      />
      <Handle
        id="b"
        type="source"
        position={Position.Right}
        className="w-1 rounded-full bg-gray-500"
      />
    </div>
  );
}

export default TextNode;
