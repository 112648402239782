import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { SiChatbot } from "react-icons/si";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import  MdNotificationsNone  from  "../pages/components/images/Asset 71mdpi.png";
import Chat from "../pages/chat/Chat";
import BulkMessages from "./BulkMessages";
import Temp from "./App";
import App from "../Chatbot/App";
import DataTable from "./TemplateData";
import Contacts from "../Contact Detail/Contacts";
import Admin_profile from "../Admin/Admin_profile";
import logo1 from "../pages/components/assets/icons/EchobotWhite.png";
import logo2 from "../pages/components/images/aws_logo.png";
import Template from "../pages/components/assets/icons/Template.png";
import Newtemp from "../pages/components/assets/icons/Create_New.png";
import Viewtemp from "../pages/components/assets/icons/View_Template.png";
import Createborad from "../pages/components/assets/icons/New_Broadcast.png";
import Contact from "../pages/components/assets/icons/Contact.png";
import Chatbot from "../pages/components/assets/icons/chatbot.png";
import Chating from "../pages/components/assets/icons/Chat.png";
import Setting from "../pages/components/assets/icons/Settings.png";
import VUser from "../pages/components//assets/icons/View_Profile.png";
import Lgout from "../pages/components/assets/icons/Logout.png";
import ViewAdmin from "../Admin/ViewAdmin";
import Temp_Build from "./Temp_Bulid";
import DashChart from "./DashChart";
import whiteContactIc from "../pages/components/assets/icons/New_User.png";
import ExcelG from '../pages/components/assets/icons/XLS_Sheet.png';
import ContactIc from '../pages/components/assets/icons/New_User.png';
import { useSelector, useDispatch } from "react-redux";
import { Rightscreen } from "../Redux Toolkit/slice";
import { useAuth } from "../pages/Auth/AuthContext";
import "../Chatbot/index.css";
import "reactflow/dist/style.css";
import '../Style/CSS.css';
import { ApiEndPoint, decodeToken } from "../pages/components/utils/utlis";
import axios from "axios";
import ContactFormModal6 from "../pages/ContactForm/ContactForm6";
import ContactFormModal2 from "../pages/ContactForm/ContactForm2";
import ContactFormModal from "../pages/ContactForm/ContactForm";
import { borderRadius, margin, padding } from "@mui/system";
import { fetchUsers } from "../Redux Toolkit/reduxmodels/BotData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal'; // You can use any modal library

Modal.setAppElement('#root');


const Dashboard = () => {
  const jwtToken = sessionStorage.getItem("jwtToken");
  const decod = decodeToken(jwtToken);
  const { decodedToken } = decod;
  const { userId } = decodedToken;
  const { logout, role } = useAuth();
  const [white, setWhite] = useState(true);
  const [Toggleleftscreen, setToggleleftscreen] = useState(true);
  const [messageTemplateArrow, setMessageTemplateArrow] = useState(false);
  const [messageTemplateFunctionality, setMessageTemplateFunctionality] =
    useState(false);
  const [adminData, setAdminData] = useState(false);
  const [roleChk, setRoleCHK] = useState(true);
 
  const [showModal, setShowModal] = useState(false); // State for the main modal
  const [showContactModal, setshowContactModal] = useState(false);
  const [showContactModal2, setshowContactModal2] = useState(false);
  const [sendMessageArrow, setSendMessageArrow] = useState(false);
  const [sendMessageFunctionality, setSendMessageFunctionality] =
    useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isContactIc,setContactiIc]=useState(false)

  const toggleMessageTemplate = () => {
    setMessageTemplateArrow(!messageTemplateArrow);
    setMessageTemplateFunctionality(!messageTemplateFunctionality);
  };
  const [broadcast, setbroadcast] = useState(false);
  const [broadcastFunctionality, setbroadcastFunctionality] = useState(false);
  const rightSCreen = useSelector((state) => state.Data.count);
  const [screenName, setScreenName] = useState("Dashboard");
  const dispatch = useDispatch();
  const changeScreen = (value, name) => {
    dispatch(Rightscreen(value));
    localStorage.setItem('rightScreen', value);
    setScreenName(name);
    localStorage.setItem('screenName', name); // Save screenName to localStorage
  };
  
  useEffect(() => {
    const savedScreen = localStorage.getItem('rightScreen');
    const savedScreenName = localStorage.getItem('screenName');
    
    if (savedScreen) {
      dispatch(Rightscreen(savedScreen)); // Update the state with the saved screen value
    }
    
    if (savedScreenName) {
      setScreenName(savedScreenName); // Update the state with the saved screen name
    }
  }, [dispatch]);
  

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsPopupOpen(true); // Open the popup
  };

  const handleConfirmLogout = () => {
    setIsPopupOpen(false); // Close the popup
    logout(); // Call the logout function
  };

  const handleCancelLogout = () => {
    setIsPopupOpen(false); // Close the popup without logging out
  };
  useEffect(() => {
    if (role === "admin") {
      setRoleCHK(false);
      console.log(roleChk);
    } else {
      setRoleCHK(true);
    }
  }, [role, roleChk]);
  const togglebroadcast = () => {
    setbroadcast(!broadcast);
    setbroadcastFunctionality(!broadcastFunctionality);
  };
  //used to change right side page data
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const closeContactModal = () => {
    setshowContactModal(false);
  };
  const closeContactModal2 = () => {
    setshowContactModal2(false);
  };
  const openContactModal = () => {
    setshowContactModal(true);
  };
  const openContactModal2 = () => {
    setshowContactModal2(true);
  };

  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUse, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUse = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/admins/${userId}`);
      setAdminData(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  var right = "0";
  // if (rightSCreen === "") {
  //   right = (
  //     <div>
  //       <div className="gap-3 m-0 text-center center d-flex flex-column align-items-center ">
  //         <SiChatbot className="fs-2 " style={{ fontWeight: "light" }} />
  //         <div>
  //           <h4>Welcome to AWS CRM</h4>
  //           <p>
  //             Your one stop solution to manage your WhatsApp Business Account
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
  if (rightSCreen==="0") {
    right=<DashChart/>
  }
  if (rightSCreen === "1") {
    right = <Temp />;
  }
  if (rightSCreen === "2") {
    right = <BulkMessages />;
  }
  if (rightSCreen === "4") {
    right = <DataTable />;
  }
  if (rightSCreen === "5") {
    right = <Contacts />;
  }
  if (rightSCreen === "6") {
    right = <Chat  isCollapsed={isCollapsed} />;
  }
  if (rightSCreen === "7") {
    right = <Admin_profile />;
  }
  if (rightSCreen === "8") {
    right = <ViewAdmin />;
  }
  if (rightSCreen === "9") {
    right = <Temp_Build />;
  }
  if(rightSCreen === "10"){
    right = <App />
  }

  // Function to toggle the background color
  const toggleBackgroundColor = () => {
    setWhite(!white);
  };
  useEffect(() => {
    if (role === "admin") {
      setRoleCHK(false);
    } else {
      setRoleCHK(true);
    }
  }, [role, roleChk]);

  useEffect(() => {
    // Replace with the actual user ID
  
    // Initial fetch
    dispatch(fetchUsers(userId));
  
    // Interval to fetch users every 10 seconds
    const intervalId = setInterval(() => {
      dispatch(fetchUsers(userId));
    }, 60000);
  
    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const toggleSendMessage = () => {
    setSendMessageArrow(!sendMessageArrow);
    setSendMessageFunctionality(!sendMessageFunctionality);
  };
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);

  const toggleTemplate = () => {
    setIsTemplateOpen(!isTemplateOpen);
    setIsSettingOpen(false);
 
  };
  const toggleSetting = () => {
    setIsSettingOpen(!isSettingOpen);
    setIsTemplateOpen(false);
  
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="dashboard-container">
    <div
      className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}
      style={{
        width: isCollapsed ? '60px' : '250px',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.2)', 
        height: '94.6vh',
      maxWidth:"200px",
      minWidth:!isCollapsed?"200px":"60px"
      }}

    > 
      <div className="collapse-toggle" onClick={toggleCollapse}>
        {isCollapsed ? (
          <FaChevronRight
            style={{
              color: 'white',
              fontSize: '10px', // Updated font size
            }}
          />
        ) : (
          <FaChevronLeft
            style={{
              color: 'white',
              fontSize: '10px', // Updated font size
            }}
          />
        )}
      </div>

      <div
        style={{ marginTop: "2px", marginLeft: "10px", cursor: 'pointer',display: 'flex', alignItems: 'center'}}
        onClick={() => {
          setScreenName('Dashboard');
          changeScreen('0',"Dashboard");
          setIsTemplateOpen(false);
          setIsSettingOpen(false);
        }}
      >
        {white ? (
          <img src={logo1} alt="error" height='20px' width='20px' /> // Updated size
        ) : (
          <img
            src={logo2}
            alt="error"
            height='24px' width='24px'// Updated size
            style={{ marginTop: 2 }}
          />
        )}
        {!isCollapsed&& (
        <span style={{ marginLeft: "10px", fontSize: "16px", fontWeight:"bold", color: white ? 'white' : 'black' }}>
          Ecco Bot
        </span>
      )}

        
      </div>

      <div className="menu">
        <div
          className="menu-item"
          onClick={() => {
            setScreenName('Dashboard');
            changeScreen('0',"Dashboard");
            setIsTemplateOpen(false);
            setIsSettingOpen(false);
            setIsCollapsed(true)
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            borderRadius: '8px',
            backgroundColor: screenName==="Dashboard"?"#FF7640":"transparent"
          }}
          onMouseEnter={(e) => {
            if (screenName!=="Dashboard") {
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            }
           
          }}
          
          onMouseLeave={(e) => {
            if (screenName!=="Dashboard"){ e.currentTarget.style.backgroundColor = 'transparent';}
           
          }}
        >
          <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon 
              icon={faHome} 
              color="white" 
              style={{ height: '20px', width: '20px' }} // Standardized size
            />
            {!isCollapsed && (
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: 'white',
                  marginLeft: '20px',
                }}
              >
                Dashboard
              </span>
            )}
          </div>
        </div>

        <div
            className="menu-item"
            onClick={() => {
              setScreenName('View Templates');
              changeScreen('4',"View Templates");
              setIsCollapsed(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding:"8px",
              borderRadius: '8px',
              backgroundColor: screenName==="View Templates"? "#FF7640":"transparent"
            }}
            onMouseEnter={(e) => {
              if (screenName!=="View Templates") {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }
             
            }}
            
            onMouseLeave={(e) => {
              if (screenName!=="View Templates") { e.currentTarget.style.backgroundColor = 'transparent';}
             
            }}
          ><div className="menu-icon-text">
  <img src={Viewtemp} height="20px" width="20px" /> {/* Standardized size */}
            {!isCollapsed && (
              <span style={{ fontSize: '14px',
                fontWeight: 'bold', color: 'white',  }}>
                View Templates
              </span>
            )}
          </div>
          
          </div>

      

        <div
          className="menu-item"
          onClick={() => {
            setScreenName('Create Broadcast');
            changeScreen('2','Create Broadcast');
            setIsTemplateOpen(false);
            setIsSettingOpen(false);
            setIsCollapsed(true)
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            borderRadius: '8px',
            backgroundColor: screenName==="Create Broadcast"? "#FF7640":"transparent"
          }}
          onMouseEnter={(e) => {
            if (screenName!=="Create Broadcast") {
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            }
           
          }}
          
          onMouseLeave={(e) => {
            if (screenName!=="Create Broadcast") { e.currentTarget.style.backgroundColor = 'transparent';}
           
          }}
        >
          <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Createborad} height="20px" width="20px" /> {/* Standardized size */}
            {!isCollapsed && (
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: 'white',
                  marginLeft: '10px',
                }}
              >
                Create Broadcast
              </span>
            )}
          </div>
        </div>

        <div
      className="menu-item"
      onClick={() => {
        setScreenName("Client List");
        changeScreen('5',"Client List");
          setIsTemplateOpen(false);
        setIsSettingOpen(false);
        setIsCollapsed(true)
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        borderRadius: '8px',
        
        backgroundColor: screenName==="Client List"? "#FF7640":"transparent"
      }}
      onMouseEnter={(e) => {
        if (screenName!=="Client List") {
          e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
        }
       
      }}
      
      onMouseLeave={(e) => {
        if (screenName!=="Client List") { e.currentTarget.style.backgroundColor = 'transparent';}
       
      }}
    >
      <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
        <img src={Contact} height={20} width={20} />
        {!isCollapsed && (
          <span
            style={{
              fontSize: 14, // Reduced font size
              fontWeight: 'bold',
              color: 'white',
              marginLeft: '10px',
            }}
          >
            Client List
          </span>
        )}
      </div>
    </div>

        <div
      className="menu-item"
      onClick={() => {
        setScreenName('Chatbot Builder');
        changeScreen('10',"Chatbot Builder");
        setIsTemplateOpen(false);
        setIsSettingOpen(false);
        setIsCollapsed(true)
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        borderRadius: '8px',
        backgroundColor: screenName==="Chatbot Builder"? "#FF7640":"transparent"
      }}
      onMouseEnter={(e) => {
        if (screenName!=="Chatbot Builder") {
          e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
        }
       
      }}
      
      onMouseLeave={(e) => {
        if (screenName!=="Chatbot Builder") { e.currentTarget.style.backgroundColor = 'transparent';}
       
      }}
    >
      <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
        <img src={Chatbot} height={20} width={20} />
        {!isCollapsed && (
          <span
            style={{
              fontSize: 14, // Reduced font size
              fontWeight: 'bold',
              color: 'white',
              marginLeft: '10px',
            }}
          >
            Chatbot Builder
          </span>
        )}
      </div>
    </div>
    <div
      className="menu-item"
      onClick={() => {
        setScreenName('Chat');
        changeScreen('6',"Chat");
        setIsTemplateOpen(false);
        setIsSettingOpen(false);
        setIsCollapsed(true)
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        borderRadius: '8px',
        
        backgroundColor: screenName==="Chat"? "#FF7640":"transparent"
      }}
      onMouseEnter={(e) => {
        if (screenName!=="Chat") {
          e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
        }
       
      }}
      
      onMouseLeave={(e) => {
        if (screenName!=="Chat") { e.currentTarget.style.backgroundColor = 'transparent';}
       
      }}
    >
      <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
        <img src={Chating} height={20} width={20} />
        {!isCollapsed && (
          <span
            style={{
              fontSize: 14, // Reduced font size
              fontWeight: 'bold',
              color: 'white',
              marginLeft: '10px',
            }}
          >
            Chat
          </span>
        )}
      </div>
    </div>
    <div
      className="menu-item"
      onClick={toggleSetting}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        borderRadius: '8px',
      
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
          
        
       
      }}
      
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = 'transparent';
       
      }}
    >
      <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
        <img src={Setting} height={20} width={20} />
        {!isCollapsed && (
          <span
            style={{
              fontSize: 14, // Reduced font size
              fontWeight: 'bold',
              color: 'white',
              marginLeft: '10px',
            }}
          >
            Settings
          </span>
        )}
      </div>
      {!isCollapsed &&
        (isSettingOpen ? (
          <FaChevronDown
            style={{
              color: 'white',
              fontSize: '14px',
              
            }}
          />
        ) : (
          <FaChevronRight
            style={{
              color: 'white',
              fontSize: '14px',
              
            }}
          />
        ))}
    </div>
    <div className={`submenu ${isSettingOpen ? '' : 'collapsed'}`}>
      {roleChk && (
        <div
          className="submenu-item"
          onClick={() => {
            setScreenName('View User');
            changeScreen('8',"View User");
            setIsCollapsed(true)
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding:"1px",

            borderRadius: '8px',
            backgroundColor: screenName==="View User"? "#FF7640":"transparent"
          }}
          onMouseEnter={(e) => {
            if (screenName!=="View User") {
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            }
           
          }}
          
          onMouseLeave={(e) => {
            if (screenName!=="View User") { e.currentTarget.style.backgroundColor = 'transparent';}
           
          }}
        >
          <img src={VUser} height={20} width={20} />
          {!isCollapsed && (
            <span style={{ fontSize: 12, color: 'white', marginLeft: '20px' }}> {/* Reduced font size */}
              View User
            </span>
          )}
        </div>
      )}
      <div
        className="submenu-item"
        onClick={handleLogoutClick}
        style={{
          display: 'flex',
          alignItems: 'center',
       padding:"1px",
       marginTop:"8px",
          borderRadius: '8px',
          
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = 'transparent';
        }}
      >
        <img src={Lgout} height={20} width={20} />
        {!isCollapsed && (
          <span style={{ fontSize: 12, color: 'white', marginLeft: '20px' }}> {/* Reduced font size */}
            Logout
          </span>
        )}
      </div>
    </div>
  </div>
</div>


      <div className="main-content">
        <div className="header">
          <div className="left-section">
            <span className="screen-name">{screenName}</span>
            {screenName === "Client List" ? (
              <div className="icon-group w-15 h-15">
                {/* <img src={ExcelG} height={20} width={20}  onClick={handleShowModal} /> */}
                <img 
                
                src={isContactIc?whiteContactIc:ContactIc} 
                height={15} 
                width={15} 
                onMouseEnter={(e) => {
                  setContactiIc(true)
                  e.currentTarget.style.backgroundColor = "#FF7640";
                }}
                onMouseLeave={(e) => {
                  setContactiIc(false)
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
                onClick={openContactModal} />
              </div>
            ) : screenName === "View User" ? (
              <div className="icon-group">
                <img src={ContactIc} height={15} width={15} onClick={openContactModal2} />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="profile-section">
            <img src={MdNotificationsNone} height={15} width={15} className="notification-icon" />
            <div className="profile-info">
              <img
                src={
                  adminData.profilePicture
                    ? `data:image/jpeg;base64,${adminData.profilePicture}`
                    : "https://bootdey.com/img/Content/avatar/avatar1.png"
                }
                style={{
                  cursor: 'pointer',
                  borderColor: '#24D366',
                  borderWidth: 1,
                  borderRadius: '50%',
                }}
                height={30}
                width={30}
                onClick={() => {
                  setScreenName('Profile');
                  changeScreen("7","Profile");
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="right-screen"
         
        >
          <p>{right}</p>
        </div>
      </div>
      <ContactFormModal6 isOpen={showModal} onClose={handleCloseModal} />
      <ContactFormModal2 isOpen={showContactModal2} onClose={closeContactModal2} />
      <ContactFormModal isOpen={showContactModal} onClose={closeContactModal} />
      <Modal
  isOpen={isPopupOpen}
  onRequestClose={handleCancelLogout}
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      borderRadius: '10px',
      backgroundColor: '#F5F7FA', // Light background
      color: '#2E3A59', // Dark text color
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
      textAlign: 'center',
      maxWidth: '350px', // Limiting width for better design
      width: '100%',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)', // Lighter overlay
    },
  }}
>
  <h2 style={{ fontSize: '18px', marginBottom: '15px', fontWeight: '500', color: '#2E3A59' }}>
    Confirm logout
  </h2>
  <p style={{ fontSize: '14px', color: '#2E3A59' }}>
    Are you sure you want to log out?
  </p>
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <button
      onClick={handleCancelLogout}
      style={{
        padding: '10px 20px',
        marginRight: '10px',
        borderRadius: '20px',
        backgroundColor: '#ffffff', // White background for cancel button
        color: '#2E3A59', // Dark text color for cancel button
        border: '1px solid #E0E4EB', // Border for cancel button
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f2f5'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ffffff'}
    >
      Cancel
    </button>
    <button
      onClick={handleConfirmLogout}
      style={{
        padding: '10px 20px',
        borderRadius: '20px', // Rounded buttons for a modern look
        backgroundColor: '#A66CFF', // Blue color for OK button
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#4553b5'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#A66CFF'}
    >
      OK
    </button>
  </div>
</Modal>


    </div>
  );
};

export default Dashboard;
